import { Box, Button, Tab, Tabs } from "@mui/material";
import { Add as AddIcon, ListAltRounded } from "@mui/icons-material";
import { AgentsTopBarProps } from "../utils/types";
import React, { SyntheticEvent } from "react";
import { ReactComponent as ReiterateLogoBlue } from "../../../assets/icons/logoLargeBlue.svg";
import { ReactComponent as AgentIconBlack } from "../../../assets/icons/agentIconBlack.svg";

export const AgentsTopBar: React.FC<AgentsTopBarProps> = ({
  selectedTab,
  onTabChange,
  onCreateAgent,
}) => {
  const handleChange = (_: SyntheticEvent, newValue: number) => {
    onTabChange(newValue);
  };

  return (
    <Box sx={{ display: "flex", borderBottom: "0.5px solid #dcdcdc", width: "100%" }}>
      <Box sx={{ py: 2, display: "flex", alignItems: "center", width: "100%" }}>
        <Box sx={{ display: "flex", alignItems: "center", height: "100%", width: "100%" }}>
          <ReiterateLogoBlue width="116px" style={{ margin: "0 20px 0 24px" }} />
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            sx={{
              minHeight: "unset",
              "& .MuiTabs-flexContainer": {
                display: "flex",
                alignItems: "center !important",
                maxHeight: "32px",
              },
              ".MuiTab-iconWrapper": {
                marginBottom: "0 !important",
                marginRight: "4px",
              },
              ".MuiTab-root": {
                borderRadius: "24px !important",
              },
              ".Mui-selected": {
                color: `#142E90 !important`,
                backgroundColor: "#EEF1FB",
                minHeight: "unset",
              },
            }}
            TabIndicatorProps={{
              style: {
                backgroundColor: "transparent",
              },
            }}
          >
            <Tab
              label="All agents"
              sx={{
                color: "black",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                height: "32px",
              }}
              icon={selectedTab === 0 ? <AgentIconBlack /> : <AgentIconBlack />}
            />
            <Tab
              label="Runs"
              sx={{
                color: "black",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                height: "32px",
              }}
              icon={<ListAltRounded fontSize="small" />}
            />
          </Tabs>
          <Box ml="auto" mr={2}>
            <Button
              size="small"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={onCreateAgent}
            >
              Create agent
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
