import { Box } from "@mui/material";
import React from "react";
import { Message } from "../../utils/agentTypes";
import { PromptEditorInterfaceState } from "../../utils/types";
import { MessageBox, MessageBoxes } from "./MessageBoxes";
import { AgentTextInput } from "./AgentTextInput";
import { ActionButtons } from "./ActionButtons";

interface LeftPanelProps {
  messages: Message[];
  selectedIndex: number | null;
  interfaceState: PromptEditorInterfaceState;
  currentMessage: string;
  lastMessageRef: React.RefObject<HTMLDivElement>;
  onClickMessage: (index: number) => void;
  onPromptingCompleted: () => void;
  onKeyPress: (event: React.KeyboardEvent) => void;
  onSendMessage: () => void;
  onMessageChange: (value: string) => void;
  onAcceptAction: () => void;
  onRejectAction: () => void;
}

export const LeftPanel: React.FC<LeftPanelProps> = ({
  messages,
  selectedIndex,
  interfaceState,
  currentMessage,
  lastMessageRef,
  onClickMessage,
  onPromptingCompleted,
  onKeyPress,
  onSendMessage,
  onMessageChange,
  onAcceptAction,
  onRejectAction,
}) => {
  return (
    <Box sx={{ width: "35%", display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Box
          sx={{
            height: "calc(100vh - 56px)",
            flexDirection: "column",
            overflowY: "scroll",
            px: 3,
          }}
        >
          <MessageBoxes
            messages={messages}
            selectedIndex={selectedIndex}
            interfaceState={interfaceState}
            waitingAgentResponse={interfaceState === "WaitingForAgent"}
            handleClickMessage={onClickMessage}
            handlePromptingCompleted={onPromptingCompleted}
          />
          {interfaceState === "WaitingForAgent" && (
            <MessageBox loading selected={true} side="left" />
          )}
          <Box ref={lastMessageRef} />
        </Box>

        <Box sx={{ alignItems: "center", minHeight: 100, px: 3 }}>
          {interfaceState === "WaitingForConfirmation" && (
            <ActionButtons onAccept={onAcceptAction} onReject={onRejectAction} />
          )}
          {interfaceState === "WaitingForPrompt" && (
            <AgentTextInput
              onKeyPress={onKeyPress}
              onSend={onSendMessage}
              value={currentMessage}
              onChange={onMessageChange}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
