import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import StyledTableCell from "../../../../components/Styled/StyledTableCell";
import StyledTableRow from "../../../../components/Styled/StyledTableRow";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Order, OrderBy } from "../../utils/types";
import { tableHeadersAgentConfig } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SettingsIcon from "@mui/icons-material/Settings";
import { useBoApiClient } from "../../../../hooks/useBoApiClient";
import { WebAgentDto } from "../../../../spec/bo";
import AgentRunAlert from "../AgentRunAlert";
import { Delete } from "@mui/icons-material";

interface AgentsTableProps {
  agents: WebAgentDto[];
  onAgentDeleted: () => void;
}

const AgentsTable = ({ agents, onAgentDeleted }: AgentsTableProps): React.JSX.Element => {
  const [orderBy, setOrderBy] = useState<OrderBy>("created_date");
  const [order, setOrder] = useState<Order>("asc");
  const [agentsView, setAgentsView] = useState<WebAgentDto[]>(agents);
  const [showRunAlert, setShowRunAlert] = useState(false);
  const [runningAgent, setRunningAgent] = useState<WebAgentDto | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [agentToDelete, setAgentToDelete] = useState<WebAgentDto | null>(null);

  const boApiClient = useBoApiClient();

  const navigate = useNavigate();

  const navigateToAgent = (agent: WebAgentDto) => {
    navigate(`/app/agents/${agent.id}/settings`);
  };

  const handleDelete = async (agent: WebAgentDto) => {
    try {
      await boApiClient.proxyWebAgentAgents.deleteAgent(agent.id);
      onAgentDeleted();
      setDeleteDialogOpen(false);
      setAgentToDelete(null);
    } catch (error) {
      console.error("Failed to delete agent:", error);
    }
  };

  useEffect(() => {
    const sortedData = agents.sort((a, b) => {
      return a > b ? -1 : 1;
    });
    setAgentsView(sortedData);
  }, [orderBy, order]);

  const handleSort = (property: OrderBy) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function runAgent(agent: WebAgentDto) {
    setRunningAgent(agent);
    setShowRunAlert(true);
    boApiClient.proxyWebAgentAgents.startRun(agent.id);

    setTimeout(() => {
      setShowRunAlert(false);
      setRunningAgent(null);
    }, 5000);
  }

  return (
    <>
      <TableContainer>
        <Table
          aria-label="saved runs table"
          sx={{ tableLayout: "fixed", width: "100%", borderBottom: "1px solid lightgrey" }}
        >
          <TableHead>
            <TableRow sx={{ borderBottom: "1px solid lightgrey" }}>
              {tableHeadersAgentConfig.map((header, index) => (
                <StyledTableCell
                  key={index}
                  hasDivider={header.hasDivider}
                  style={{
                    width: header.width,
                    minWidth: header.minWidth,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    color: "rgba(0,0,0,0.6)",
                    fontWeight: 400,
                  }}
                >
                  {header.sortable ? (
                    <TableSortLabel
                      active={orderBy === header.id}
                      direction={orderBy === header.id ? order : "desc"}
                      onClick={handleSort(header.id)}
                      IconComponent={ArrowDropUpIcon}
                      sx={{
                        "& .MuiTableSortLabel-icon": {
                          opacity: 1,
                          color: "rgba(0, 0, 0, 0.54) !important",
                        },
                      }}
                    >
                      {header.label}
                    </TableSortLabel>
                  ) : (
                    header.label
                  )}
                </StyledTableCell>
              ))}
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {agentsView.length > 0 ? (
              agentsView.map(agent => (
                <StyledTableRow key={agent.id} sx={{ cursor: "pointer" }}>
                  <StyledTableCell style={{ width: tableHeadersAgentConfig[0].width }}>
                    <Typography fontSize={12} noWrap>
                      {agent.name}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell style={{ width: tableHeadersAgentConfig[1].width }}>
                    {agent.config.url}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: "5%" }}>
                    <Tooltip title="Run agent">
                      <IconButton onClick={() => runAgent(agent)}>
                        <PlayArrowIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Reconfigure agent">
                      <IconButton onClick={() => navigateToAgent(agent)}>
                        <SettingsIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete agent">
                      <IconButton
                        onClick={() => {
                          setAgentToDelete(agent);
                          setDeleteDialogOpen(true);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <TableRow style={{ height: "120px", borderBottom: "1px solid lightgrey" }}>
                <StyledTableCell colSpan={tableHeadersAgentConfig.length} align="center">
                  <Typography
                    variant="body1"
                    fontSize={12}
                    fontWeight={400}
                    color="rgba(0,0,0,0.6)"
                  >
                    There are no saved runs.
                  </Typography>
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Delete Agent</DialogTitle>
        <DialogContent>
          Are you sure you want to delete agent "{agentToDelete?.name}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={() => agentToDelete && handleDelete(agentToDelete)} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {showRunAlert && runningAgent && (
        <AgentRunAlert
          agentName={runningAgent.name}
          onClose={() => {
            setShowRunAlert(false);
            setRunningAgent(null);
          }}
        />
      )}
    </>
  );
};

export default AgentsTable;
