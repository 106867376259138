import React from "react";
import { Box } from "@mui/system";
import AgentSetupStepper, { AgentSetupStepperProps } from "./AgentSetupStepper";

interface AgentSetupStepperWindowProps {
  children: React.ReactNode;
  stepperOptions: AgentSetupStepperProps;
}

const AgentSetupStepperWindow = ({ children, stepperOptions }: AgentSetupStepperWindowProps) => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <AgentSetupStepper {...stepperOptions} />
      {children}
    </Box>
  );
};

export default AgentSetupStepperWindow;
