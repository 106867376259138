import { Box, Button } from "@mui/material";
import React from "react";

interface ActionButtonsProps {
  onAccept: () => void;
  onReject: () => void;
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({ onAccept, onReject }) => (
  <Box sx={{ display: "flex", flexDirection: "row" }}>
    <Button onClick={onAccept} variant="contained" fullWidth sx={{ mr: 2 }}>
      Confirm action
    </Button>
    <Button
      onClick={onReject}
      variant="outlined"
      fullWidth
      sx={{
        backgroundColor: "white",
        cursor: "pointer !important",
        ml: 2,
      }}
    >
      Decline
    </Button>
  </Box>
);
