import React, { useState } from "react";
import {
  Box,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import robotIcon from "../../../../assets/icons/robotIcon.svg";
import StyledTableCell from "../../../../components/Styled/StyledTableCell";
import StyledTableRow from "../../../../components/Styled/StyledTableRow";
import DownloadIcon from "@mui/icons-material/Download";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AgentRunWithAgent } from "../../utils/types";
import { tableHeadersCompletedRuns, tableHeadersScheduledRuns } from "../../utils/tableHeaders";
import { useBoApiClient } from "../../../../hooks/useBoApiClient";
import StyledChip from "../../../../components/Styled/StyledChip";
import StyledAvatar from "../../../../components/Styled/StyledAvatar";
import { WebAgentDto } from "../../../../spec/bo";
import { formatDate } from "../../utils/utils";

interface AgentRunsTableProps {
  agentsRuns: AgentRunWithAgent[];
  variant: "scheduled" | "completed";
}

interface MenuState {
  anchorEl: HTMLElement | null;
  runId: string | null;
}

const AgentRunsTable = ({ agentsRuns, variant }: AgentRunsTableProps): React.JSX.Element => {
  const [menuState, setMenuState] = useState<MenuState>({ anchorEl: null, runId: null });
  const [isLoading, setIsLoading] = useState<Record<string, boolean>>({});

  const boApiClient = useBoApiClient();
  const tableHeaders =
    variant === "scheduled" ? tableHeadersScheduledRuns : tableHeadersCompletedRuns;

  const handleFileClick = async (
    e: React.MouseEvent,
    runId: string,
    fileName: string,
    agentId: string
  ) => {
    e.stopPropagation();
    const loadingKey = `${runId}-${fileName}`;

    try {
      setIsLoading(prev => ({ ...prev, [loadingKey]: true }));
      const blobData = await boApiClient.proxyWebAgentAgents.downloadFile(runId, agentId, fileName);

      const url = window.URL.createObjectURL(blobData);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setIsLoading(prev => ({ ...prev, [loadingKey]: false }));
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, runId: string) => {
    event.stopPropagation();
    setMenuState({ anchorEl: event.currentTarget, runId });
  };

  const handleMenuClose = () => {
    setMenuState({ anchorEl: null, runId: null });
  };

  const renderTableCell = (header: string, agent: WebAgentDto, run: any) => {
    switch (header) {
      case "Browser agent name":
        return (
          <Typography fontSize={12} noWrap>
            {agent.name}
          </Typography>
        );
      case "Status":
        return variant === "completed" ? (
          !run.result || run.result.success === null ? (
            <StyledChip label="In Progress" type="blue" />
          ) : run.result.success ? (
            <StyledChip label="Success" type="green" />
          ) : (
            <StyledChip label="Failed" type="grey" />
          )
        ) : (
          <StyledChip label="Scheduled" type="blue" />
        );
      case "Output":
        return variant === "scheduled" ? (
          <Typography sx={{ fontSize: 12, color: "rgba(0,0,0,0.35)" }}>
            Created in the future
          </Typography>
        ) : (
          <>
            {run.files.length === 0 ? (
              <Typography sx={{ fontSize: 12, color: "rgba(0,0,0,0.35)" }}>N/A</Typography>
            ) : (
              run.files.map((file, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mb: index !== run.files.length - 1 ? 1 : 0,
                  }}
                >
                  <Link
                    component="button"
                    onClick={e => handleFileClick(e, run.id, file.name || "", agent.id)}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                      color: "primary.main",
                      cursor: "pointer",
                      "&:hover": { textDecoration: "underline" },
                    }}
                  >
                    <Typography variant="body1" fontSize={12} fontWeight={400} sx={{ mr: 1 }}>
                      {file.name}
                    </Typography>
                    {isLoading[`${run.id}-${file.name}`] ? (
                      <Box
                        sx={{
                          width: 16,
                          height: 16,
                          border: "2px solid rgba(0,0,0,0.1)",
                          borderTop: "2px solid currentColor",
                          borderRadius: "50%",
                          animation: "spin 1s linear infinite",
                          "@keyframes spin": {
                            "0%": { transform: "rotate(0deg)" },
                            "100%": { transform: "rotate(360deg)" },
                          },
                        }}
                      />
                    ) : (
                      <DownloadIcon sx={{ fontSize: 16 }} />
                    )}
                  </Link>
                </Box>
              ))
            )}
          </>
        );
      case "Run by":
        return variant === "scheduled" ? (
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <StyledAvatar>
              <img alt="Robot" src={robotIcon} width="16.6px" height="16.6px" />
            </StyledAvatar>
            <Typography
              variant="body1"
              fontSize={12}
              fontWeight={400}
              sx={{ ml: 1 }}
              color="rgba(0,0,0,0.6)"
            >
              Automatic
            </Typography>
          </Box>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            {run.userName ? (
              <StyledAvatar>{run.userName.charAt(0).toUpperCase()}</StyledAvatar>
            ) : (
              <StyledAvatar>
                <img alt="Robot" src={robotIcon} width="16.6px" height="16.6px" />
              </StyledAvatar>
            )}
            <Typography
              variant="body1"
              fontSize={12}
              fontWeight={400}
              sx={{ ml: 1 }}
              color="rgba(0,0,0,0.6)"
            >
              {run.userName || "Automatic"}
            </Typography>
          </Box>
        );
      case "Starting URL":
        return (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Typography fontSize={12} noWrap sx={{ maxWidth: "calc(100% - 40px)" }}>
              {agent.config.url}
            </Typography>
            <IconButton size="small" onClick={e => handleMenuOpen(e, run.id)} sx={{ ml: 1 }}>
              <MoreVertIcon fontSize="small" />
            </IconButton>
          </Box>
        );
      case "Run completed at":
        return <Typography fontSize={12}>{formatDate(run.result?.finishedAt)}</Typography>;

      case "Run scheduled for":
        return <Typography fontSize={12}>{formatDate(run.result?.startedAt)}</Typography>;
      default:
        return null;
    }
  };

  return (
    <>
      <TableContainer>
        <Table
          aria-label={`${variant} runs table`}
          sx={{ tableLayout: "fixed", width: "100%", borderBottom: "1px solid lightgrey" }}
        >
          <TableHead>
            <TableRow sx={{ borderBottom: "1px solid lightgrey" }}>
              {tableHeaders.map((header, index) => (
                <StyledTableCell
                  key={index}
                  hasDivider={header.hasDivider}
                  sx={{
                    width: header.width,
                    minWidth: header.minWidth,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    color: "rgba(0,0,0,0.6)",
                    fontWeight: 400,
                  }}
                >
                  {header.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {agentsRuns.length > 0 ? (
              agentsRuns.map(({ agent, run }) => (
                <StyledTableRow key={run.id}>
                  {tableHeaders.map((header, index) => (
                    <StyledTableCell key={index} sx={{ width: header.width }}>
                      {renderTableCell(header.label, agent, run)}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))
            ) : (
              <TableRow style={{ height: "120px", borderBottom: "1px solid lightgrey" }}>
                <StyledTableCell colSpan={tableHeaders.length} align="center">
                  <Typography variant="body1" fontSize={12} color="rgba(0,0,0,0.6)">
                    {variant === "scheduled"
                      ? "There are no scheduled runs."
                      : "There are no completed runs."}
                  </Typography>
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Menu
        anchorEl={menuState.anchorEl}
        open={Boolean(menuState.anchorEl)}
        onClose={handleMenuClose}
        slotProps={{
          paper: {
            sx: {
              boxShadow: "0px 2px 4px rgba(0,0,0,0.4)",
              mt: 0.5,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={handleMenuClose}
          sx={{
            fontSize: "12px",
            color: "error.main",
            py: 1,
            minWidth: "120px",
          }}
        >
          Cancel run
        </MenuItem>
        <MenuItem
          onClick={handleMenuClose}
          sx={{
            fontSize: "12px",
            py: 1,
            minWidth: "120px",
          }}
        >
          View details
        </MenuItem>
      </Menu>
    </>
  );
};

export default AgentRunsTable;
