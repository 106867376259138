import { useEffect, useState } from "react";
import { useBoApiClient } from "../../../hooks/useBoApiClient";
import { WebAgentDto } from "../../../spec/bo";

export const useAgents = (selectedTab: number) => {
  const [agents, setAgents] = useState<WebAgentDto[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const boApiClient = useBoApiClient();

  const fetchAgents = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await boApiClient.proxyWebAgentAgents.getAllAgents();
      setAgents(response);
    } catch (error) {
      console.error("Failed to fetch agents:", error);
      setError(error instanceof Error ? error : new Error("Failed to fetch agents"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAgents();
  }, [selectedTab]);

  return { agents, loading, error, refetch: fetchAgents };
};
