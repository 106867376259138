import { TableHeader } from "./types";

export const tableHeadersCompletedRuns: TableHeader[] = [
  {
    label: "Browser agent name",
    width: "20%",
    minWidth: "150px",
    hasDivider: false,
    sortable: false,
  },
  { label: "Status", width: "15%", minWidth: "100px", hasDivider: false, sortable: false },
  {
    label: "Run completed at",
    width: "15%",
    minWidth: "120px",
    hasDivider: false,
    sortable: false,
  },
  { label: "Run by", width: "15%", minWidth: "100px", hasDivider: false, sortable: false },
  { label: "Output", width: "15%", minWidth: "100px", hasDivider: false, sortable: false },
  { label: "Starting URL", width: "20%", minWidth: "150px", hasDivider: false, sortable: false },
];

export const tableHeadersScheduledRuns: TableHeader[] = [
  {
    label: "Browser agent name",
    width: "20%",
    minWidth: "150px",
    hasDivider: false,
    sortable: false,
  },
  { label: "Status", width: "15%", minWidth: "100px", hasDivider: false, sortable: false },
  {
    label: "Run scheduled for",
    width: "15%",
    minWidth: "120px",
    hasDivider: false,
    sortable: false,
  },
  { label: "Run by", width: "15%", minWidth: "100px", hasDivider: false, sortable: false },
  { label: "Output", width: "15%", minWidth: "100px", hasDivider: false, sortable: false },
  { label: "Starting URL", width: "20%", minWidth: "150px", hasDivider: false, sortable: false },
];
