import { AgentsEmptyState } from "./AgentsEmptyState";
import { Box } from "@mui/material";
import AgentsTable from "./AgentsTable";
import React from "react";
import { WebAgentDto } from "../../../../spec/bo";

interface AgentsListViewProps {
  webAgents: WebAgentDto[];
  onClick: () => void;
  onAgentDeleted: () => void;
}

export const AgentsListView: React.FC<AgentsListViewProps> = ({
  webAgents,
  onClick,
  onAgentDeleted,
}) => (
  <>
    {webAgents.length === 0 ? (
      <AgentsEmptyState onClick={onClick} />
    ) : (
      <Box sx={{ width: "80%", overflow: "auto", mt: 2, alignItems: "center" }}>
        <AgentsTable agents={webAgents} onAgentDeleted={onAgentDeleted} />
      </Box>
    )}
  </>
);
