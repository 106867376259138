import { TableHeader } from "./types";
import { Agent } from "./agentTypes";

const _prompt =
  "1. Log in. Use the placeholders $username, $password and $otp when filling the relevant fields. The real values will be substituted by the system.\n" +
  "2. Go to transactions under operations.\n" +
  '3. Specify a date range from 2024-07-25 to 2024-07-26. Try clicking on the current time range (like "Today") to change to a custom range. You may need to filter the options by typing "custom" to find it. Filters are irrelevant.\n' +
  "4. Export a CSV file of the transactions and wait for the download to start.";

const common_vars = {
  prompt: _prompt,
  username: "reiterate_demo",
  password: "reiterate_demo",
  two_factor_secret: "1GHAS252",
};

const dummyAgents: Agent[] = [
  {
    id: "1",
    name: "Nuvei | daily transactions",
    schedule: "daily",
    lastRunStatus: "success",
    created_date: "2021-10-01",
    ...common_vars,
    starting_url: "https://cpanel.nuvei.com/login",
  },
  {
    id: "2",
    name: "Plixpay | monthly transactions",
    schedule: "monthly",
    lastRunStatus: "failed",
    created_date: "2021-10-02",
    ...common_vars,
    starting_url: "https://services.plixpay.com/wallet/login/",
  },
  {
    id: "3",
    name: "GatewayCrypto | daily transactions",
    schedule: "daily",
    lastRunStatus: "started",
    created_date: "2021-10-03",
    ...common_vars,
    starting_url: "https://app.gatewaycrypto.io",
  },
  {
    id: "4",
    name: "Skrill | daily transactions",
    schedule: "daily",
    lastRunStatus: "success",
    created_date: "2021-10-01",
    ...common_vars,
    starting_url: "https://merchant.paysafe.com/skrill/login",
  },
  {
    id: "5",
    name: "Bitpace | monthly transactions",
    schedule: "monthly",
    lastRunStatus: "failed",
    created_date: "2021-10-02",
    ...common_vars,
    starting_url: "https://merchant.bitpace.com/auth/login",
  },
  {
    id: "6",
    name: "Kasha | daily transactions",
    schedule: "daily",
    lastRunStatus: "started",
    created_date: "2021-10-03",
    ...common_vars,
    starting_url: "https://merchant-portal.kasha.tech/sign-in",
  },
  {
    id: "7",
    name: "Payneteasy | daily transactions",
    schedule: "daily",
    lastRunStatus: "success",
    created_date: "2021-10-01",
    ...common_vars,
    starting_url: "https://gate.dns-pay.com/paynet-ui/login-step1",
  },
  {
    id: "8",
    name: "DNSPay | monthly transactions",
    schedule: "monthly",
    lastRunStatus: "failed",
    created_date: "2021-10-02",
    ...common_vars,
    starting_url: "https://gate.dns-pay.com/paynet-ui/login-step1",
  },
];

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

async function fetchAgents() {
  await delay(100 + Math.random() * 1400);
  return dummyAgents;
}

async function fetchAgent(id: string) {
  await delay(100 + Math.random() * 1400);
  return dummyAgents.filter(agent => agent.id === id)[0];
}

const tableHeadersAgentConfig: TableHeader[] = [
  { label: "Name", width: "40%", minWidth: "100px", hasDivider: false, sortable: true },
  { label: "Starting URL", width: "30%", minWidth: "80px", hasDivider: false, sortable: true },
];

const tableHeadersAgentRunsCompleted: TableHeader[] = [
  { label: "Name", width: "20%", minWidth: "100px", hasDivider: false, sortable: false },
  { label: "Status", width: "15%", minWidth: "100px", hasDivider: false, sortable: false },
  { label: "Output", width: "15%", minWidth: "80px", hasDivider: false, sortable: false },
  { label: "Run by", width: "15%", minWidth: "80px", hasDivider: false, sortable: false },
  { label: "Run completed at", width: "15%", minWidth: "80px", hasDivider: false, sortable: false },
  { label: "Starting URL", width: "20%", minWidth: "80px", hasDivider: false, sortable: false },
];

const tableHeadersAgentRunsScheduled: TableHeader[] = [
  { label: "Name", width: "20%", minWidth: "100px", hasDivider: false, sortable: false },
  { label: "Status", width: "15%", minWidth: "100px", hasDivider: false, sortable: false },
  { label: "Output", width: "15%", minWidth: "80px", hasDivider: false, sortable: false },
  {
    label: "Run by",
    width: "15%",
    minWidth: "80px",
    hasDivider: false,
    sortable: false,
    mutedColors: true,
  },
  {
    label: "Run scheduled for",
    width: "15%",
    minWidth: "80px",
    hasDivider: false,
    sortable: false,
    mutedColors: true,
  },
  {
    label: "Starting URL",
    width: "20%",
    minWidth: "80px",
    hasDivider: false,
    sortable: true,
    mutedColors: true,
  },
];

export {
  tableHeadersAgentConfig,
  tableHeadersAgentRunsCompleted,
  tableHeadersAgentRunsScheduled,
  fetchAgents,
  fetchAgent,
};

export const formatDate = (dateString: string | null): string => {
  if (!dateString) return "N/A";
  const date = new Date(dateString);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${day}.${month}.${year} at ${hours}:${minutes}`;
};
