import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Camera } from "lucide-react";

interface TwoFactorInstructionsProps {
  onStartScan: () => void;
}

const TwoFactorInstructions = ({ onStartScan }: TwoFactorInstructionsProps) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 4 }}>
      <Typography variant="subtitle1" fontWeight="bold">
        Set up 2FA to complete login
      </Typography>

      <Box component="ol" sx={{ pl: 2 }}>
        <Typography component="li" sx={{ mb: 1 }}>
          Go to Google Authenticator or 1Password mobile application
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          Find how you can transfer accounts to create a QR code that will give access to the 2FA
          outputs
        </Typography>
        <Typography component="li" sx={{ mb: 2 }}>
          Once the QR code is created open your camera and display the QR code to Reiterate
        </Typography>
      </Box>

      <Button
        variant="contained"
        startIcon={<Camera size={20} />}
        onClick={onStartScan}
        sx={{ maxWidth: "fit-content" }}
      >
        Open camera to scan QR
      </Button>
    </Box>
  );
};

export default TwoFactorInstructions;
