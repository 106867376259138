import { useAuth0 } from "@auth0/auth0-react";
import Hotjar from "@hotjar/browser";
import { LinearProgress } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import * as Sentry from "@sentry/react";
import { default as MixPanel } from "mixpanel-browser";
import "handsontable/dist/handsontable.full.css";
import { Suspense, useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import AppContainer from "./containers/AppContainer";
import ProjectsContainer from "./containers/ProjectsContainer";
import "./pages/Agents/components/AgentScheduleView/AgentScheduleView.css";
import LaunchDarklyProvider from "./context/LaunchDarklyProvider";
import MaintenanceProvider from "./context/MaintenanceProvider";
import MixpanelContext from "./context/MixpanelContext";
import { SignalRProvider } from "./context/SignalRProvider";
import UIContext from "./context/UIContext";
import { accessTokenRequest } from "./core/auth";
import { lazyWithReload } from "./core/lazyWithRetry";
import { useBoApiClient } from "./hooks/useBoApiClient";
import { AgentScheduleView } from "./pages/Agents/components/AgentScheduleView/AgentScheduleView";
import AgentSettingsView from "./pages/Agents/components/AgentSettingsView/AgentSettingsView";
import AgentsView from "./pages/Agents/components/AgentsView";
import PromptEditorView from "./pages/Agents/components/PromptEditor/PromptEditorView";
import Login from "./pages/Auth/Login";
import NoOrganizations from "./pages/Auth/NoOrganizations";
import Maintenance from "./pages/Maintenance";
import { theme } from "./themeSettings";
import { AUTH0_ORG_CLAIM_URL } from "./utils/auth/config";
import { devMixpanelConfig, prodMixpanelConfig } from "./utils/mixpanel/configs";
import FrontendTracer from "./utils/telemetry/FrontendTracer";

const ProjectsGateway = lazyWithReload(() => import("./pages/Projects/ProjectsGateway"));
const IntegrationsRedirectView = lazyWithReload(
  () => import("./pages/Integrations/IntegrationsRedirect")
);
const IntegrationsView = lazyWithReload(() => import("./pages/Integrations/IntegrationsView"));
const IntegrationsViewItem = lazyWithReload(
  () => import("./pages/Integrations/IntegrationsViewItem")
);
const SettingsView = lazyWithReload(() => import("./pages/Settings/SettingsGateway"));
const MatchingView = lazyWithReload(() => import("./pages/Matching/MatchingGateway"));
const ProjectsViewItem = lazyWithReload(() => import("./pages/Projects/ProjectsViewItem"));
const UploadView = lazyWithReload(() => import("./pages/Upload/Upload"));
const LookerExplore = lazyWithReload(() => import("./pages/Looker/LookerExplore"));
const LookerDashboard = lazyWithReload(() => import("./pages/Looker/LookerDashboard"));
const SettlementView = lazyWithReload(() => import("./pages/SettlementView"));
const WorkflowView = lazyWithReload(() => import("./pages/Workflow/WorkflowView"));

LicenseInfo.setLicenseKey(
  "02f590b3d489e781eab2c8e3d17c6ac7Tz03MDc2NSxFPTE3MjEwMzE1NzgwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

function App() {
  const { isLoading, isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const boApiClient = useBoApiClient();
  const [isSourceModalFlowOpen, setSourceModalFlowOpen] = useState(false);
  const [dataViewNodeId, setDataViewNodeId] = useState(null);
  const [toolVisibilitySettings, setToolVisibilitySettings] = useState(null);
  const [isLoadingSettings, setIsLoadingSettings] = useState(false);

  const [mixpanel] = useState(MixPanel);

  const setVisibilitySettings = async () => {
    setIsLoadingSettings(true);
    boApiClient.request.config.TOKEN = await getAccessTokenSilently(accessTokenRequest);
    const results = await boApiClient.settings.getSettings("tool_visibility_settings");
    const visibilitySettings = results || {};
    const reiterate = user.email.includes("@iter8.ai") || user.email.includes("@reiterate.com");
    setToolVisibilitySettings({ reiterate, ...visibilitySettings });
    setIsLoadingSettings(false);
  };

  if (import.meta.env.PROD && isAuthenticated) {
    Hotjar.identify(user.sub, {
      email: user.email,
      org: user[AUTH0_ORG_CLAIM_URL],
    });
  }

  useEffect(() => {
    if (isAuthenticated) {
      setVisibilitySettings();
      if (import.meta.env.PROD) {
        mixpanel.init(prodMixpanelConfig.token, {
          ...prodMixpanelConfig.attributes,
        });
      } else {
        mixpanel.init(devMixpanelConfig.token, {
          ...devMixpanelConfig.attributes,
        });
        mixpanel.disable();
      }
      mixpanelIdentity();
    }
  }, [isAuthenticated]);

  const mixpanelIdentity = () => {
    mixpanel.identify(user.name);
    mixpanel.register({
      name: user.name,
      $email: user.email,
      org: user[AUTH0_ORG_CLAIM_URL],
      sub: user.sub,
    });
  };

  if (import.meta.env.PROD) {
    const siteId = 3667881;
    const hotjarVersion = 6;

    Hotjar.init(siteId, hotjarVersion);
  }

  if (typeof window !== "undefined") {
    FrontendTracer();
  }

  if (isAuthenticated) {
    Sentry.configureScope(function (scope) {
      scope.setTag("schema", user[AUTH0_ORG_CLAIM_URL]);
      scope.setUser({
        email: user.email,
      });
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <SignalRProvider>
        <LaunchDarklyProvider>
          <MaintenanceProvider>
            <MixpanelContext.Provider value={{ mixpanel }}>
              <UIContext.Provider
                value={{
                  isSourceModalFlowOpen,
                  setSourceModalFlowOpen,
                  dataViewNodeId,
                  setDataViewNodeId,
                  toolVisibilitySettings,
                  setToolVisibilitySettings,
                }}
              >
                <Suspense fallback={<LinearProgress color="primary" />}>
                  <Routes>
                    <Route
                      path="/app"
                      element={
                        isLoading ? (
                          <LinearProgress color="primary" />
                        ) : isAuthenticated ? (
                          isLoadingSettings ? (
                            <LinearProgress color={"primary"} />
                          ) : (
                            <Outlet />
                          )
                        ) : (
                          <Navigate to="/login" />
                        )
                      }
                    >
                      <Route path="/app/maintenance" element={<Maintenance />} />
                      <Route
                        path="/app/agents/"
                        element={
                          <Suspense fallback={<AppContainer />}>
                            <AgentsView />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/app/agents/:agentUuid/settings"
                        element={
                          <Suspense fallback={<AppContainer />}>
                            <AgentSettingsView />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/app/agents/:agentUuid/chat"
                        element={
                          <Suspense fallback={<AppContainer />}>
                            <PromptEditorView />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/app/agents/:agentUuid/schedule"
                        element={
                          <Suspense fallback={<AppContainer />}>
                            <AgentScheduleView />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/app/tasks"
                        element={
                          <Suspense fallback={<AppContainer />}>
                            <WorkflowView />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/app/projects"
                        element={
                          <Suspense fallback={<AppContainer />}>
                            <ProjectsGateway />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/app/projects/unassigned/dashboard"
                        element={<Navigate to="/app/projects/unassigned/estimation" />}
                      />
                      <Route
                        path="/app/projects/:projectUuid"
                        element={
                          <Suspense fallback={<ProjectsContainer />}>
                            <ProjectsViewItem />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/app/projects/:projectUuid/:type"
                        element={
                          <Suspense fallback={<ProjectsContainer />}>
                            <ProjectsViewItem />
                          </Suspense>
                        }
                      />

                      <Route
                        path="/app/integrations/redirect"
                        element={
                          <Suspense fallback={<AppContainer />}>
                            <IntegrationsRedirectView />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/app/integrations"
                        element={
                          <Suspense fallback={<AppContainer />}>
                            <IntegrationsView />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/app/integrations/:type/new"
                        element={
                          <Suspense fallback={<AppContainer />}>
                            <IntegrationsViewItem isNew />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/app/integrations/:type/:id"
                        element={
                          <Suspense fallback={<AppContainer />}>
                            <IntegrationsViewItem />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/app/settings"
                        element={
                          <Suspense fallback={<AppContainer />}>
                            <SettingsView />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/app/matching"
                        element={
                          <Suspense fallback={<AppContainer />}>
                            <MatchingView />
                          </Suspense>
                        }
                      />

                      <Route
                        path="/app/upload"
                        element={
                          <Suspense fallback={<AppContainer />}>
                            <UploadView />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/app/explore"
                        element={
                          <Suspense fallback={<AppContainer />}>
                            <LookerExplore />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/app/dashboard"
                        element={
                          <Suspense fallback={<AppContainer />}>
                            <LookerDashboard />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/app/settlement"
                        element={
                          <Suspense fallback={<AppContainer />}>
                            <SettlementView />
                          </Suspense>
                        }
                      />
                      <Route path="/app/" element={<Navigate to="/app/upload" />} />
                    </Route>
                    <Route
                      path="/"
                      element={
                        isLoading ? (
                          <LinearProgress color="primary" />
                        ) : isAuthenticated ? (
                          <Navigate to="/app" />
                        ) : (
                          <Outlet />
                        )
                      }
                    >
                      <Route path="/login" element={<Login />} />
                      <Route path="/" element={<Navigate to="/login" />} />
                    </Route>
                    <Route path="/no-organizations" element={<NoOrganizations />} />
                    <Route path="/*" element={<Navigate to="/" />} />
                  </Routes>
                </Suspense>
              </UIContext.Provider>
            </MixpanelContext.Provider>
          </MaintenanceProvider>
        </LaunchDarklyProvider>
      </SignalRProvider>
    </ThemeProvider>
  );
}

export default App;
