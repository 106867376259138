import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import Hotkeys from "react-hot-keys";
import { useLocation } from "react-router";
import { useNavigate, useParams } from "react-router-dom";
import { ConnectionStatus, useAgentSession } from "../../../../hooks/useAgentSession";
import { useBoApiClient } from "../../../../hooks/useBoApiClient";
import { AgentStep } from "../../../../spec/agent";
import { AgentConfigDto, WebAgentDto } from "../../../../spec/bo";
import AgentSetupStepperWindow from "../AgentSetupStepperWindow";
import { Message } from "../../utils/agentTypes";
import SidebarWindow from "../SidebarWindow";
import { PromptEditorInterfaceState } from "../../utils/types";
import { RightPanel } from "./RightPanel";
import { LeftPanel } from "./LeftPanel";

interface PromptEditorProps {}

// User enters prompt
// Agent is thinking - returns thought & proposes action
// User accepts or rejects action

export type PromptLine = string;

const PromptEditorView = ({}: PromptEditorProps): React.JSX.Element => {
  const [messages, setMessages] = useState<Message[]>([{ type: "tutorial" }]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(messages.length - 1);
  const [selectedStep, setSelectedStep] = useState<Message | null>(null);
  const [interfaceState, setInterfaceState] =
    useState<PromptEditorInterfaceState>("WaitingForConnection");
  const [currentMessage, setCurrentMessage] = useState<string>("");
  const { agentUuid } = useParams();
  const {
    connectionStatus: connectionStatusSession,
    sessionUuid: sessionUuidSession,
    startSession,
    deleteSession,
  } = useAgentSession(agentUuid, handleConnectStateChange);
  const [connectionStatus, setConnectionStatus] =
    useState<ConnectionStatus>(connectionStatusSession);
  const [sessionUuid, setSessionUuid] = useState<string | null>(sessionUuidSession);
  const [firstInteraction, setFirstInteraction] = useState<boolean>(true);
  const [agent, setAgent] = useState<WebAgentDto | null>(null);

  const [prompt, setPrompt] = useState<PromptLine[]>([]);

  const lastMessageRef = useRef(null);

  function scrollToElement() {
    lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    scrollToElement();
  }, [messages, interfaceState]);

  const boApiClient = useBoApiClient();

  let messagesBuffer = [];
  const navigate = useNavigate();
  const location = useLocation();

  function handleConnectStateChange(uuid: string, status: ConnectionStatus) {
    setConnectionStatus(status);
    setSessionUuid(uuid);
    if (status === "connected") {
      if (interfaceState === "WaitingForConnection") {
        setInterfaceState("WaitingForPrompt");
      }
    } else if (status === "disconnected") {
      setInterfaceState("WaitingForConnection");
    }
  }

  useEffect(() => {
    if (connectionStatus === "connected" && firstInteraction) {
      requestAgentThought(sessionUuid);
    }
  }, [connectionStatus]);

  function handlePromptingCompleted() {
    navigate(`/app/agents/${agentUuid}/schedule`);
  }

  function handleClickMessage(index: number) {
    const setIndex = selectedIndex === index ? null : index;
    setSelectedIndex(setIndex);
    if (setIndex !== null && messages[index].type === "agent") {
      setSelectedStep(messages[index]);
    } else {
      setSelectedStep(null);
    }
  }

  function handleAddAgentMessage(step: AgentStep) {
    const m: Message = { type: "agent", message: step };
    messagesBuffer.push(m);
    if (step.action === null) {
      setInterfaceState("WaitingForPrompt");
    } else {
      setInterfaceState("WaitingForConfirmation");
    }
    const newHistory = [...messages, ...messagesBuffer];
    setMessages(newHistory);
    setSelectedIndex(newHistory.length - 1);
    setSelectedStep(newHistory[selectedIndex]);
  }

  // function to fetch agent response as if it came externally
  async function requestAgentThought(sessionUuid: string, accept = true) {
    setInterfaceState("WaitingForAgent");

    // ask agent for next step
    console.log(sessionUuid);
    boApiClient.proxyWebAgentSessions.stepSession(sessionUuid, accept).then((step: AgentStep) => {
      if (firstInteraction) {
        // if the agent came back with a message that still requires approval,
        // save the last message to the stack and request approval for next action
        setSelectedStep({ type: "agent", message: step });
        setFirstInteraction(false);
      }

      handleAddAgentMessage(step);
    });
  }

  function createPrompt(newPrompt: PromptLine[]) {
    return newPrompt.map((line, i) => i + 1 + ": " + line).join("\n");
  }

  function handleSendMessage() {
    if (connectionStatus !== "connected") {
      return;
    }

    if (currentMessage === "") {
      return;
    }

    const m: Message = { type: "user", message: currentMessage };
    messagesBuffer.push(m);

    setPrompt([...prompt, currentMessage]);
    setMessages([...messages.filter((m: Message) => m.type !== "done"), ...messagesBuffer]);
    setCurrentMessage("");

    const request = createPrompt([...prompt, currentMessage]);
    boApiClient.proxyWebAgentSessions.setPrompt(sessionUuid, request).then(() => {
      requestAgentThought(sessionUuid, false);
    });
  }

  function handleAcceptAgentAction() {
    requestAgentThought(sessionUuid);
  }

  function handleRejectAgentAction() {
    if (messages.at(-2)?.type === "user") {
      setMessages(messages.slice(0, messages.length - 2));
      setInterfaceState("WaitingForPrompt");
    } else {
      setMessages(messages.slice(0, messages.length - 1));
      setInterfaceState("WaitingForPrompt");
    }
  }

  function teardownComponent(sessionUuid: string) {
    if (sessionUuid) {
      deleteSession();
    }
    return;
  }

  useEffect(() => {
    startSession();
    console.log(agentUuid);
    boApiClient.proxyWebAgentAgents.getAgent(agentUuid).then(a => {
      setAgent(a);
    });
  }, []);

  useEffect(() => {
    setSelectedStep(messages[selectedIndex]);
  }, [selectedIndex]);

  useEffect(() => {
    teardownComponent(sessionUuid);
  }, [location]);

  const handleKeyPress = (keyName, event, handle) => {
    if (interfaceState === "WaitingForPrompt") {
      if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
        event.preventDefault();
        handleSendMessage();
      }
    } else if (interfaceState === "WaitingForConfirmation") {
      if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
        event.preventDefault();
        handleAcceptAgentAction();
      }
    }
  };

  const handleSave = async () => {
    const agentConfig: AgentConfigDto = {
      url: agent?.config?.url,
      prompt: createPrompt(prompt),
      options: agent?.config?.options,
      parameters: agent?.config?.parameters,
    };
    await boApiClient.proxyWebAgentAgents.updateAgent(agentUuid, agentConfig);
    navigate(`/app/agents/${agentUuid}/schedule`);
  };

  return (
    <SidebarWindow>
      <Hotkeys
        keyName="ctrl+enter,command+enter"
        onKeyDown={handleKeyPress}
        onKeyUp={handleKeyPress}
      />
      <AgentSetupStepperWindow
        stepperOptions={{ agentUuid, activeStep: 1, handleNextButtonSave: handleSave }}
      >
        <Box sx={{ width: "calc(100vw - 64px)" }}>
          <Box sx={{ display: "flex", flexDirection: "column", height: "calc(100vh - 69px)" }}>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                flex: 1,
                overflow: "hidden",
              }}
            >
              <LeftPanel
                messages={messages}
                selectedIndex={selectedIndex}
                interfaceState={interfaceState}
                currentMessage={currentMessage}
                lastMessageRef={lastMessageRef}
                onClickMessage={handleClickMessage}
                onPromptingCompleted={handlePromptingCompleted}
                onKeyPress={e => handleKeyPress(null, e, null)}
                onSendMessage={handleSendMessage}
                onMessageChange={setCurrentMessage}
                onAcceptAction={handleAcceptAgentAction}
                onRejectAction={handleRejectAgentAction}
              />

              <RightPanel
                connectionStatus={connectionStatus}
                selectedStep={selectedStep}
                onStartSession={startSession}
              />
            </Box>
          </Box>
        </Box>
      </AgentSetupStepperWindow>
    </SidebarWindow>
  );
};

export default PromptEditorView;
