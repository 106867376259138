import { Box, LinearProgress } from "@mui/material";

const Loader = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <LinearProgress variant="indeterminate" sx={{ width: "100%" }} color="primary" />
      )}
      {!isLoading && <Box sx={{ width: "100%", backgroundColor: "#020227", height: 4 }} />}
    </>
  );
};

export default Loader;
