/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FilterNodeOptionsDto } from '../models/FilterNodeOptionsDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FilterNodeAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns FilterNodeOptionsDto Success
   * @throws ApiError
   */
  public getConfigOptions(): CancelablePromise<FilterNodeOptionsDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/workflows/blocks/filter-node',
    });
  }

}
