import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import jsQR from "jsqr";

interface QRScannerProps {
  onScanComplete: (qrData: string) => void;
  onError: (error: string) => void;
}

const QRScanner = ({ onScanComplete, onError }: QRScannerProps) => {
  const [isInitializing, setIsInitializing] = useState(true);
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const animationRef = useRef<number>();

  const scanQRCode = () => {
    if (!videoRef.current || !canvasRef.current) return;

    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    if (!context || video.readyState !== video.HAVE_ENOUGH_DATA) {
      animationRef.current = requestAnimationFrame(scanQRCode);
      return;
    }

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

    const code = jsQR(imageData.data, imageData.width, imageData.height, {
      inversionAttempts: "dontInvert",
    });

    if (code && code.data && code.data.trim() !== "") {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }

      onScanComplete(code.data);
    } else {
      animationRef.current = requestAnimationFrame(scanQRCode);
    }
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: "environment",
          width: { ideal: 1280 },
          height: { ideal: 720 },
        },
      });

      streamRef.current = stream;

      const videoElement = videoRef.current;
      if (!videoElement) {
        throw new Error("Video element not found");
      }

      videoElement.srcObject = stream;

      await new Promise<void>(resolve => {
        if (!videoElement) return;

        const handlePlay = () => {
          setIsInitializing(false);
          resolve();
        };

        videoElement.addEventListener("playing", handlePlay, { once: true });
      });

      requestAnimationFrame(scanQRCode);
    } catch (error) {
      onError("Failed to initialize camera. Please check permissions.");
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (videoRef.current) {
        startCamera();
      }
    }, 100);

    return () => {
      clearTimeout(timeoutId);
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        maxWidth: "400px",
        margin: "0 auto",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          paddingTop: "100%",
          bgcolor: "black",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <video
            ref={videoRef}
            autoPlay
            playsInline
            muted
            style={{
              minWidth: "100%",
              minHeight: "100%",
              objectFit: "cover",
            }}
          />
        </Box>

        <canvas ref={canvasRef} style={{ display: "none" }} />
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "70%",
            height: "70%",
            border: "2px solid rgba(255, 255, 255, 0.8)",
            borderRadius: "8px",
            boxShadow: "0 0 0 9999px rgba(0, 0, 0, 0.5)",
            "&::before": {
              content: '""',
              position: "absolute",
              top: -1,
              left: -1,
              right: -1,
              bottom: -1,
              border: "1px solid rgba(255, 255, 255, 0.3)",
              borderRadius: "8px",
            },
            "&::after": {
              content: '""',
              position: "absolute",
              width: "20px",
              height: "20px",
              border: "2px solid rgba(255, 255, 255, 0.8)",
              borderRadius: "1px",
              top: "-2px",
              left: "-2px",
              borderBottom: "none",
              borderRight: "none",
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: "20px",
              height: "20px",
              border: "2px solid rgba(255, 255, 255, 0.8)",
              borderRadius: "1px",
              top: "-2px",
              right: "-2px",
              borderBottom: "none",
              borderLeft: "none",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              width: "20px",
              height: "20px",
              border: "2px solid rgba(255, 255, 255, 0.8)",
              borderRadius: "1px",
              bottom: "-2px",
              left: "-2px",
              borderTop: "none",
              borderRight: "none",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              width: "20px",
              height: "20px",
              border: "2px solid rgba(255, 255, 255, 0.8)",
              borderRadius: "1px",
              bottom: "-2px",
              right: "-2px",
              borderTop: "none",
              borderLeft: "none",
            }}
          />
        </Box>
      </Box>

      {isInitializing ? (
        <Typography sx={{ mt: 2, textAlign: "center", color: "text.secondary" }}>
          Initializing camera...
        </Typography>
      ) : (
        <Typography sx={{ mt: 2, textAlign: "center", color: "text.secondary" }}>
          Center the QR code within the square
        </Typography>
      )}
    </Box>
  );
};

export default QRScanner;
