import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { AgentsViewProps } from "../utils/types";
import { useAgents } from "../hooks/useAgents";
import { useBoApiClient } from "../../../hooks/useBoApiClient";
import AppSidebar from "../../../components/Sidebar/AppSidebar";
import { AgentsTopBar } from "./AgentsTopBar";
import { AgentsListView } from "./AgentsListView/AgentsListView";
import { AgentRuns } from "./AgentRuns/AgentRuns";
import { CreateAgentDialog } from "./CreateAgentDialog";
import Loader from "../../../components/Loader/Loader";

export const AgentsView: React.FC<AgentsViewProps> = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();
  const { agents, loading, error, refetch } = useAgents(selectedTab);
  const boApiClient = useBoApiClient();

  const handleCreateAgent = async (name: string) => {
    try {
      const agent = await boApiClient.proxyWebAgentAgents.createAgent(name, {
        url: "",
        prompt: "",
        parameters: {},
      });
      navigate(`/app/agents/${agent.id}/settings`);
      setIsDialogOpen(false);
      refetch();
    } catch (error) {
      console.error("Failed to create agent:", error);
      // TODO: Add proper error handling here
    }
  };

  const handleEmptyStateClick = () => {
    setIsDialogOpen(true);
  };

  const handleCancelCreate = () => {
    setIsDialogOpen(false);
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "row",
        flex: 1,
        overflow: "hidden",
      }}
    >
      <AppSidebar />
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flex: 1,
          overflowY: "auto",
        }}
      >
        <AgentsTopBar
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
          onCreateAgent={() => setIsDialogOpen(true)}
        />
        <Box
          component="section"
          id="agents-container"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          {loading ? (
            <Loader isLoading={loading} />
          ) : error ? (
            <Box sx={{ p: 2, color: "error.main" }}>Failed to load agents. Please try again.</Box>
          ) : (
            <>
              {selectedTab === 0 && (
                <AgentsListView
                  webAgents={agents}
                  onClick={handleEmptyStateClick}
                  onAgentDeleted={refetch}
                />
              )}
              {selectedTab === 1 && <AgentRuns agents={agents} />}
            </>
          )}
        </Box>
      </Box>

      {isDialogOpen && (
        <CreateAgentDialog onSuccess={handleCreateAgent} onCancel={handleCancelCreate} />
      )}
    </Box>
  );
};

export default AgentsView;
