import { Box } from "@mui/system";
import { Divider, Typography } from "@mui/material";
import React from "react";

interface AgentDoneMessageBoxProps {
  selected?: boolean;
  loading?: boolean;
  done?: boolean;
  clickHandler?: () => void;
  finishRunHandler?: () => void;
}

export const AgentDoneMessageBox = ({
  clickHandler,
  selected = false,
}: AgentDoneMessageBoxProps) => {
  const unselectedColor = "#C7E3F0";
  const selectedColor = "#A1B8C2";
  const message_box = (
    <Box style={{ padding: 18 }} onClick={clickHandler}>
      <Divider>
        <Typography color="#666">Your last prompt is completed</Typography>
      </Divider>
      <Typography color="#666" align={"center"}>
        Great! What next? Type the next prompt or click here to review the complete instructions.
      </Typography>
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        mt: 4,
        marginInline: 12,
        marginBlock: 12,
        width: "100%",
      }}
    >
      <Box width="100%">{message_box}</Box>
    </Box>
  );
};
