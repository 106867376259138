import React, { useState } from "react";
import { Alert, Box, Button, TextField } from "@mui/material";
import TwoFactorInstructions from "./TwoFactorInstructions";
import QRScanner from "./QRScanner";
import { TwoFactorAuthState } from "../../utils/types";

interface TwoFactorSetupProps {
  onConfigured: (secret: string) => void;
}

const TwoFactorSetup = ({ onConfigured }: TwoFactorSetupProps) => {
  const [state, setState] = useState<TwoFactorAuthState>({
    isScanning: false,
    isConfigured: false,
  });
  const [manualUri, setManualUri] = useState("");

  const handleStartScan = () => {
    setState(prev => ({ ...prev, isScanning: true, error: undefined }));
  };

  const handleScanComplete = (qrData: string) => {
    setState(prev => ({ ...prev, isScanning: false, isConfigured: true }));
    onConfigured(qrData);
  };

  const handleError = (error: string) => {
    setState(prev => ({ ...prev, error, isScanning: false }));
  };

  const handleManualSubmit = () => {
    if (manualUri) {
      setState(prev => ({ ...prev, isConfigured: true }));
      onConfigured(manualUri);
    }
  };

  return (
    <Box sx={{ mt: 4 }}>
      {state.error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {state.error}
        </Alert>
      )}

      {state.isConfigured && (
        <Alert severity="success" sx={{ mb: 2 }}>
          TOTP URI configured
        </Alert>
      )}

      {state.isScanning ? (
        <QRScanner onScanComplete={handleScanComplete} onError={handleError} />
      ) : (
        <Box>
          <TwoFactorInstructions onStartScan={handleStartScan} />
          <Box sx={{ mt: 3 }}>
            <TextField
              fullWidth
              label="Or enter TOTP URI manually"
              placeholder="otpauth://totp/..."
              value={manualUri}
              onChange={e => setManualUri(e.target.value)}
              sx={{ maxWidth: "400px" }}
            />
            <Button
              variant="contained"
              disabled={!manualUri}
              onClick={handleManualSubmit}
              sx={{ mt: 1, ml: 2, maxWidth: "400px" }}
            >
              Set TOTP URI
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TwoFactorSetup;
