import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Box } from "@mui/material";
import Cron, { HEADER } from "react-cron-generator";
import SidebarWindow from "../SidebarWindow";
import AgentSetupStepperWindow from "../AgentSetupStepperWindow";
import { useBoApiClient } from "../../../../hooks/useBoApiClient";
import { WebAgentDto } from "../../../../spec/bo";
import Loader from "../../../../components/Loader/Loader";

const DEFAULT_CRON = "0 0 1 * *";

interface AgentScheduleViewProps {}

export const AgentScheduleView: React.FC<AgentScheduleViewProps> = () => {
  const [cron, setCron] = useState<string>(DEFAULT_CRON);
  const [agent, setAgent] = useState<WebAgentDto | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { agentUuid } = useParams<{ agentUuid: string }>();
  const boApiClient = useBoApiClient();

  const quartzToUnixCron = (quartzCron: string): string => {
    const parts = quartzCron.split(" ");
    const unixParts = parts.slice(1, 6).map(part => {
      if (part === "L") return "1";
      if (part === "?") return "*";
      return part.replace(/^0+/, "") || "0";
    });
    return unixParts.join(" ");
  };

  const unixToQuartzCron = (unixCron: string): string => {
    const parts = unixCron.split(" ");
    return `0 ${parts.join(" ")} *`;
  };

  useEffect(() => {
    if (agentUuid) {
      loadAgent();
    }
  }, [agentUuid]);

  const loadAgent = async () => {
    try {
      setLoading(true);
      const agentData = await boApiClient.proxyWebAgentAgents.getAgent(agentUuid);
      setAgent(agentData);

      if (agentData?.timers?.length > 0) {
        setCron(agentData.timers[0].schedule);
      }
    } catch (err) {
      setError("Failed to load agent data");
    } finally {
      setLoading(false);
    }
  };

  const handleCronChange = (value: string) => {
    const unixCron = quartzToUnixCron(value);
    setCron(unixCron);
    setError(null);
  };

  const handleSave = async () => {
    if (!agentUuid) return;

    try {
      setLoading(true);

      if (agent?.timers) {
        for (const timer of agent.timers) {
          if (timer.id) {
            await boApiClient.proxyWebAgentAgents.deleteTimer(timer.id, agentUuid);
          }
        }
      }

      await boApiClient.proxyWebAgentAgents.addTimer(agentUuid, cron);

      navigate("/app/agents/");
    } catch (err) {
      setError("Failed to save schedule");
    } finally {
      setLoading(false);
    }
  };

  return (
    <SidebarWindow>
      <AgentSetupStepperWindow
        stepperOptions={{
          activeStep: 2,
          agentUuid: agentUuid,
          handleNextButtonSave: handleSave,
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 3,
            gap: 3,
          }}
        >
          {error && (
            <Alert severity="error" onClose={() => setError(null)} sx={{ width: "100%" }}>
              {error}
            </Alert>
          )}

          <Box sx={{ width: "100%", maxWidth: "800px" }}>
            <Cron
              onChange={handleCronChange}
              value={unixToQuartzCron(cron)}
              showResultText={true}
              showResultCron={true}
              options={{
                headers: [HEADER.DAILY, HEADER.MONTHLY],
              }}
            />
          </Box>

          {loading && <Loader isLoading={loading} />}
        </Box>
      </AgentSetupStepperWindow>
    </SidebarWindow>
  );
};
