import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import { AgentRunDto, AgentTimerDto, WebAgentDto } from "../../../../spec/bo";
import AgentRunsTable from "./AgentRunsTable";

interface AgentRunsProps {
  agents: WebAgentDto[];
}

const createScheduledRun = (timer: AgentTimerDto): AgentRunDto => ({
  id: timer.id || "",
  timerId: timer.id,
  result: {
    success: null,
    startedAt: timer.nextTrigger,
    finishedAt: null,
  },
  files: [],
});

export function AgentRuns({ agents }: AgentRunsProps) {
  const scheduledRuns = useMemo(() => {
    return agents
      .filter(agent => agent.timers && agent.timers.length > 0)
      .flatMap(agent =>
        agent.timers!.map(timer => ({
          agent,
          run: createScheduledRun(timer),
        }))
      );
  }, [agents]);

  const historicalRuns = useMemo(() => {
    return agents.flatMap(agent =>
      (agent.runs || []).filter(run => !run.timerId).map(run => ({ agent, run }))
    );
  }, [agents]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "90%",
        mx: "auto",
        mt: 3,
        gap: 4,
      }}
    >
      <Box>
        <Typography
          variant="subtitle1"
          sx={{
            mb: 2.5,
            fontWeight: 500,
          }}
        >
          Scheduled next
        </Typography>
        <AgentRunsTable agentsRuns={scheduledRuns} variant="scheduled" />
      </Box>

      <Box>
        <Typography
          variant="subtitle1"
          sx={{
            mb: 2.5,
            fontWeight: 500,
          }}
        >
          Completed
        </Typography>
        <AgentRunsTable agentsRuns={historicalRuns} variant="completed" />
      </Box>
    </Box>
  );
}

export default AgentRuns;
