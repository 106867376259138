import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { ConnectionStatus } from "../../../../hooks/useAgentSession";
import { Message } from "../../utils/agentTypes";
import { ConnectionIndicator } from "./ConnectionIndicator";
import { BounceLoader } from "react-spinners";

interface RightPanelProps {
  connectionStatus: ConnectionStatus;
  selectedStep: Message | null;
  onStartSession: () => void;
}

export const RightPanel: React.FC<RightPanelProps> = ({
  connectionStatus,
  selectedStep,
  onStartSession,
}) => {
  const renderImage = () => {
    if (selectedStep?.type === "agent" && selectedStep?.message?.screenshot) {
      return (
        <Box sx={{ mx: 16, padding: 1 }}>
          <img
            style={{ borderRadius: 10 }}
            width="100%"
            src={`data:image/png;base64,${selectedStep.message.screenshot}`}
            alt="screenshot"
          />
        </Box>
      );
    }
    return null;
  };

  return (
    <Box
      sx={{
        width: "65%",
        backgroundColor: "#eee",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ConnectionIndicator state={connectionStatus} />
      {connectionStatus === "loading" ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "80%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography color="#6E6E6E">Connecting to browser...</Typography>
            <BounceLoader color="#6E6E6E" size={100} />
          </Box>
        </Box>
      ) : (
        renderImage()
      )}
      <Button onClick={onStartSession} sx={{ display: "none" }} />
    </Box>
  );
};
