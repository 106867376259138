import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import React from "react";

interface AgentTextInputProps {
  onKeyPress: (event: React.KeyboardEvent) => void;
  onSend: () => void;
  value: string;
  onChange: (value: string) => void;
}

export const AgentTextInput: React.FC<AgentTextInputProps> = ({
  onKeyPress,
  onSend,
  value,
  onChange,
}) => (
  <OutlinedInput
    style={{ width: "100%" }}
    minRows={1}
    maxRows={4}
    onKeyDown={onKeyPress}
    placeholder="Type your prompt..."
    endAdornment={
      <InputAdornment position="end">
        <IconButton edge="end" type="submit" onClick={onSend}>
          <SendIcon />
        </IconButton>
      </InputAdornment>
    }
    autoFocus
    multiline
    value={value}
    onChange={e => onChange(e.target.value)}
  />
);
